import * as _events2 from "events";

var _events = "default" in _events2 ? _events2.default : _events2;

import * as _debug2 from "debug";

var _debug = "default" in _debug2 ? _debug2.default : _debug2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _net2 from "net";

var _net = "default" in _net2 ? _net2.default : _net2;

import * as _tls2 from "tls";

var _tls = "default" in _tls2 ? _tls2.default : _tls2;

import _HeaderHostTransformer from "./HeaderHostTransformer";
var exports = {};
const {
  EventEmitter
} = _events;

const debug = _debug("localtunnel:client");

const fs = _fs;
const net = _net;
const tls = _tls;
const HeaderHostTransformer = _HeaderHostTransformer; // manages groups of tunnels

exports = class TunnelCluster extends EventEmitter {
  constructor(opts = {}) {
    super(opts);
    this.opts = opts;
  }

  open() {
    const opt = this.opts; // Prefer IP if returned by the server

    const remoteHostOrIp = opt.remote_ip || opt.remote_host;
    const remotePort = opt.remote_port;
    const localHost = opt.local_host || "localhost";
    const localPort = opt.local_port;
    const localProtocol = opt.local_https ? "https" : "http";
    const allowInvalidCert = opt.allow_invalid_cert;
    debug("establishing tunnel %s://%s:%s <> %s:%s", localProtocol, localHost, localPort, remoteHostOrIp, remotePort); // connection to localtunnel server

    const remote = net.connect({
      host: remoteHostOrIp,
      port: remotePort
    });
    remote.setKeepAlive(true);
    remote.on("error", err => {
      debug("got remote connection error", err.message); // emit connection refused errors immediately, because they
      // indicate that the tunnel can't be established.

      if (err.code === "ECONNREFUSED") {
        this.emit("error", new Error(`connection refused: ${remoteHostOrIp}:${remotePort} (check your firewall settings)`));
      }

      remote.end();
    });

    const connLocal = () => {
      if (remote.destroyed) {
        debug("remote destroyed");
        this.emit("dead");
        return;
      }

      debug("connecting locally to %s://%s:%d", localProtocol, localHost, localPort);
      remote.pause();

      if (allowInvalidCert) {
        debug("allowing invalid certificates");
      }

      const getLocalCertOpts = () => allowInvalidCert ? {
        rejectUnauthorized: false
      } : {
        cert: fs.readFileSync(opt.local_cert),
        key: fs.readFileSync(opt.local_key),
        ca: opt.local_ca ? [fs.readFileSync(opt.local_ca)] : undefined
      }; // connection to local http server


      const local = opt.local_https ? tls.connect({
        host: localHost,
        port: localPort,
        ...getLocalCertOpts()
      }) : net.connect({
        host: localHost,
        port: localPort
      });

      const remoteClose = () => {
        debug("remote close");
        this.emit("dead");
        local.end();
      };

      remote.once("close", remoteClose); // TODO some languages have single threaded servers which makes opening up
      // multiple local connections impossible. We need a smarter way to scale
      // and adjust for such instances to avoid beating on the door of the server

      local.once("error", err => {
        debug("local error %s", err.message);
        local.end();
        remote.removeListener("close", remoteClose);

        if (err.code !== "ECONNREFUSED") {
          return remote.end();
        } // retrying connection to local server


        setTimeout(connLocal, 1000);
      });
      local.once("connect", () => {
        debug("connected locally");
        remote.resume();
        let stream = remote; // if user requested specific local host
        // then we use host header transform to replace the host header

        if (opt.local_host) {
          debug("transform Host header to %s", opt.local_host);
          stream = remote.pipe(new HeaderHostTransformer({
            host: opt.local_host
          }));
        }

        stream.pipe(local).pipe(remote); // when local closes, also get a new remote

        local.once("close", hadError => {
          debug("local connection closed [%s]", hadError);
        });
      });
    };

    remote.on("data", data => {
      const match = data.toString().match(/^(\w+) (\S+)/);

      if (match) {
        this.emit("request", {
          method: match[1],
          path: match[2]
        });
      }
    }); // tunnel is considered open when remote connects

    remote.once("connect", () => {
      this.emit("open", remote);
      connLocal();
    });
  }

};
export default exports;